<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete team"
    description="This team will be deleted, but data about their work will be saved in analytics and other modules."
    primary-button-text="Delete team"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteTeam"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import type { Team } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  team: Team
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const deleteTeam = async () => {
  loading.value = true
  try {
    await useDeleteTeam(props.team)
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
